//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.

$primary: #04ceaa !default;

$white: #fff !default;
$gray-100: #fafcff !default;
$gray-200: #f0f2f5 !default;
$gray-300: #d8e3f0 !default;
$gray-400: #b4c2d1 !default;
$gray-500: #9baaba !default;
$gray-600: #728394 !default;
$gray-700: #5e7082 !default;
$gray-800: #4b5a69 !default;
$gray-900: #33414f !default;
$gray-1000: #2f4050 !default;
$gray-1100: #0e1621 !default;
$black: #000 !default;